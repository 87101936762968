import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import Container from 'app/components/Container';
import ContentTitle from 'app/components/ContentTitle';

const Root = styled.section`
    padding: 8rem 0;
`;

const Inner = styled.div`
    position: relative;

    @media (min-width: 1050px) {
        padding-left: 30%;

        &::before {
            background: url('/images/logo-mark.svg') no-repeat center;
            background-size: contain;
            content: '';
            height: 25rem;
            left: 0;
            position: absolute;
            top: 0;
            transform: rotate(-35deg);
            width: 25rem;
        }
    }
`;

const Content = styled.div`
    p {
        margin-bottom: 2rem;

        &:last-child {
            margin-bottom: 0;
        }
    }
`;

const Introduction = ({ children, title }) => (
    <Root>
        <Container>
            <Inner>
                <ContentTitle>{title}</ContentTitle>
                <Content>{children}</Content>
            </Inner>
        </Container>
    </Root>
);

Introduction.propTypes = {};

Introduction.defaultProps = {};

export default Introduction;
