import React, { Fragment } from 'react';
import { Helmet } from 'react-helmet';

import Banner from 'app/components/Banner';
import ContactSection from 'app/components/ContactSection';
import MobileNavigation from 'app/components/MobileNavigation';

const meta = 'We help young people gain confidence through performing arts';

const Scene = ({ children, title }) => (
    <Fragment>
        <Helmet>
            <title>{title}</title>
            <meta name="description" content={meta} />
            <link
                href="https://fonts.googleapis.com/css2?family=Josefin+Sans&display=swap"
                rel="stylesheet"
            />
        </Helmet>
        <Banner />
        <main>{children}</main>
        <ContactSection />
        <MobileNavigation />
    </Fragment>
);

export default Scene;
