import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import Link from 'app/components/Link';

const backgroundPositionMap = {
    facebook: 66.667,
    instagram: 100,
};

const Root = styled.div`
    border-top: 0.1rem var(--clr-white) dashed;
    display: flex;
    justify-content: flex-end;
    margin-top: 4rem;
    padding-top: 2rem;

    @media (min-width: 500px) {
        border-top: 0;
        margin: 0;
        padding: 0;
    }
`;

const StyledLink = styled(Link)`
    background: url('/images/contact.svg') no-repeat 0
        ${(props) => backgroundPositionMap[props.theme]}%;
    background-size: 100%;
    height: 4rem;
    margin-left: 2rem;
    overflow: hidden;
    text-indent: 100%;
    width: 4rem;

    @media (min-width: 500px) {
        height: 5rem;
        width: 5rem;
    }

    @media (min-width: 1100px) {
        height: 6rem;
        width: 6rem;
    }
`;

const Social = () => (
    <Root>
        <StyledLink
            isNewWindow
            theme="facebook"
            to="https://www.facebook.com/iperformacademyoftheatrearts/"
        >
            Facebook
        </StyledLink>
        <StyledLink isNewWindow theme="instagram" to="https://www.instagram.com/iperformacademy/">
            Instagram
        </StyledLink>
    </Root>
);

Social.propTypes = {};

Social.defaultProps = {};

export default Social;
