import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import Link from 'app/components/Link';

const Root = styled(Link)`
    background: url('/images/logo.svg') no-repeat 0 50%;
    background-size: contain;
    display: block;
    height: 6rem;
    overflow: hidden;
    text-indent: 100%;
    white-space: nowrap;
    width: 20rem;

    @media (min-width: 500px) {
        height: 7rem;
        width: 28rem;
    }

    @media (min-width: 800px) {
        height: 10rem;
        width: 35rem;
    }
`;

const Logo = () => <Root to="/">iPerform Academy of Theatre Arts</Root>;

Logo.propTypes = {};

Logo.defaultProps = {};

export default Logo;
