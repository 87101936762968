import React, { Fragment, useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import Link from 'app/components/Link';

const Root = styled.div`
    background: var(--clr-dark);
    display: ${(props) => (props.isOpen ? 'flex' : 'none')};
    flex-direction: column;
    height: 100%;
    justify-content: center;
    left: 0;
    padding: 3rem 3rem 10vh;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 10;

    @media (min-width: 800px) {
        display: none;
    }
`;

const Control = styled.button`
    background: none;
    border: 0;
    height: 7rem;
    overflow: hidden;
    position: absolute;
    top: 0;
    right: 0;
    text-indent: 9rem;
    white-space: nowrap;
    width: 8rem;

    &::before {
        border-top: 0.2rem var(--clr-white) solid;
        border-bottom: 0.2rem var(--clr-white) solid;
        content: '';
        height: 1.6rem;
        position: absolute;
        left: 3rem;
        top: 2.8rem;
        width: 2rem;
    }

    &::after {
        background-color: var(--clr-white);
        content: '';
        height: 0.2rem;
        position: absolute;
        left: 3rem;
        top: 3.5rem;
        width: 2rem;
    }

    &:focus {
        outline: none;
    }

    @media (min-width: 500px) {
        right: 1rem;
        top: 2rem;
    }

    @media (min-width: 800px) {
        display: none;
    }
`;

const List = styled.ul``;

const Item = styled.li`
    border-bottom: 0.1rem var(--clr-grey) dashed;

    &:first-child {
        border-top: 0.1rem var(--clr-grey) dashed;
    }
`;

const StyledLink = styled(Link)`
    align-items: center;
    color: var(--clr-white);
    display: flex;
    font-size: var(--fs-sml);
    font-weight: var(--fw-bold);
    height: 7rem;
    justify-content: center;
    letter-spacing: 0.1em;
    text-transform: uppercase;

    &:hover {
        color: var(--clr-white);
    }
`;

const Close = styled.button`
    background: none;
    border: 0;
    height: 7rem;
    overflow: hidden;
    position: fixed;
    top: 0;
    right: 0;
    text-indent: 9rem;
    white-space: nowrap;
    width: 8rem;

    &::before {
        background-color: var(--clr-white);
        content: '';
        height: 0.2rem;
        position: absolute;
        left: 3rem;
        top: 3.5rem;
        transform: rotate(-45deg);
        width: 2rem;
    }

    &::after {
        background-color: var(--clr-white);
        content: '';
        height: 0.2rem;
        position: absolute;
        left: 3rem;
        top: 3.5rem;
        transform: rotate(45deg);
        width: 2rem;
    }

    &:focus {
        outline: none;
    }

    @media (min-width: 500px) {
        right: 1rem;
        top: 2rem;
    }
`;

const MobileNavigation = () => {
    const [isOpen, setIsOpen] = useState(false);

    return (
        <Fragment>
            <Control type="button" onClick={() => setIsOpen(true)}>
                Menu
            </Control>
            <Root isOpen={isOpen}>
                <List>
                    <Item>
                        <StyledLink to="/">Home</StyledLink>
                    </Item>
                    <Item>
                        <StyledLink to="/uniforms">Uniforms</StyledLink>
                    </Item>
                    <Item>
                        <StyledLink to="mailto:iperformacademy@hotmail.com">
                            Get in Touch
                        </StyledLink>
                    </Item>
                </List>
                <Close type="button" onClick={() => setIsOpen(false)}>
                    Close
                </Close>
            </Root>
        </Fragment>
    );
};

MobileNavigation.propTypes = {};

MobileNavigation.defaultProps = {};

export default MobileNavigation;
