import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const Root = styled.a`
    background-color: var(--clr-theme-3);
    border-radius: 2.4rem;
    color: var(--clr-white);
    cursor: pointer;
    display: inline-block;
    font-size: var(--fs-sml);
    font-weight: var(--fw-bold);
    letter-spacing: 0.1em;
    line-height: 4.8rem;
    padding: 0 4rem;
    text-transform: uppercase;
    transition: background-color 0.2s;
    white-space: nowrap;

    &:hover {
        background-color: var(--clr-theme-2);
        color: var(--clr-white);
    }
`;

const Button = ({ children, to }) => <Root href={to}>{children}</Root>;

Button.propTypes = {};

Button.defaultProps = {};

export default Button;
