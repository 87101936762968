import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const Root = styled.div`
    margin: 0 auto;
    max-width: ${(props) =>
        props.isLimited ? 'var(--container-thin-width)' : 'var(--container-width)'};
    padding: 0 1rem;

    @media (min-width: 500px) {
        padding: 0 2rem;
    }

    @media (min-width: 800px) {
        padding: 0 4rem;
    }
`;

const Container = ({ children, isLimited }) => <Root isLimited={isLimited}>{children}</Root>;

Container.propTypes = {};

Container.defaultProps = {};

export default Container;
