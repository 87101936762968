import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import Contact from 'app/components/Contact';
import Container from 'app/components/Container';
import Social from 'app/components/Social';

const Root = styled.footer`
    background-color: var(--clr-dark);
    color: var(--clr-white);
    padding: 4rem 0 2rem;

    @media (min-width: 500px) {
        padding: 4rem 0;
    }
`;

const Inner = styled.div`
    @media (min-width: 500px) {
        align-items: flex-end;
        display: flex;
        justify-content: space-between;
    }
`;

const ContactSection = () => (
    <Root>
        <Container>
            <Inner>
                <Contact />
                <Social />
            </Inner>
        </Container>
    </Root>
);

ContactSection.propTypes = {};

ContactSection.defaultProps = {};

export default ContactSection;
