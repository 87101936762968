import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import Link from 'app/components/Link';

const Root = styled.ul`
    display: none;

    @media (min-width: 800px) {
        display: flex;
        justify-content: flex-end;
    }
`;

const Item = styled.li`
    margin-left: 2rem;

    @media (min-width: 900px) {
        margin-left: 3rem;
    }
`;

const StyledLink = styled(Link)`
    color: rgba(255, 255, 255, 0.8);
    font-family: var(--ff-title);
    font-size: var(--fs-xsml);
    letter-spacing: 0.2em;
    text-transform: uppercase;

    &:hover {
        color: var(--clr-white);
    }

    @media (min-width: 900px) {
        font-size: var(--fs-sml);
    }
`;

const PrimaryNavigation = () => (
    <Root>
        <Item>
            <StyledLink to="/">Home</StyledLink>
        </Item>
        <Item>
            <StyledLink to="/uniforms">Uniforms</StyledLink>
        </Item>
        <Item>
            <StyledLink to="mailto:iperformacademy@hotmail.com">Get in Touch</StyledLink>
        </Item>
    </Root>
);

PrimaryNavigation.propTypes = {};

PrimaryNavigation.defaultProps = {};

export default PrimaryNavigation;
