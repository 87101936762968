import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import Link from 'app/components/Link';

const backgroundPositionMap = {
    email: 0,
    phone: 33.333,
};

const colorMap = {
    email: 'theme-1',
    phone: 'theme-3',
};

const Root = styled.ul`
    @media (min-width: 800px) {
        display: flex;
    }
`;

const Item = styled.li`
    color: ${(props) => `var(--clr-${colorMap[props.theme]})`};
    margin-bottom: 2rem;

    &:last-child {
        margin-bottom: 0;
        margin-right: 0;
    }

    @media (min-width: 800px) {
        margin-bottom: 0;
        margin-right: 6rem;
    }
`;

const StyledLink = styled(Link)`
    color: inherit;
    display: flex;
    flex-direction: column;
    height: 4rem;
    justify-content: center;
    padding-left: 5rem;
    position: relative;

    &::before {
        background: url('/images/contact.svg') no-repeat 0
            ${(props) => backgroundPositionMap[props.theme]}%;
        background-size: 100%;
        content: '';
        height: 4rem;
        left: 0;
        position: absolute;
        top: 0;
        width: 4rem;
    }

    &:hover {
        color: inherit;
    }

    @media (min-width: 500px) {
        height: 5rem;
        padding-left: 6rem;

        &::before {
            height: 5rem;
            width: 5rem;
        }
    }

    @media (min-width: 1100px) {
        height: 6rem;
        padding-left: 7rem;

        &::before {
            height: 6rem;
            width: 6rem;
        }
    }
`;

const Term = styled.span`
    color: var(--clr-white);
    font-size: var(--fs-sml);
    font-weight: var(--fw-bold);
    letter-spacing: 0.1em;
    line-height: 1;
    margin-bottom: 0.5rem;
    text-transform: uppercase;

    @media (min-width: 1100px) {
    }
`;

const Def = styled.span`
    font-family: var(--ff-title);
    font-size: var(--fs-med);
    line-height: 1;

    @media (min-width: 1100px) {
        font-size: var(--fs-lrg);
    }
`;

const Contact = () => (
    <Root>
        <Item theme="email">
            <StyledLink theme="email" to="mailto:iperformacademy@hotmail.com">
                <Term>Email</Term>
                <Def>iperformacademy@hotmail.com</Def>
            </StyledLink>
        </Item>
        <Item theme="phone">
            <StyledLink theme="phone" to="tel:07702031519">
                <Term>Phone</Term>
                <Def>07702 031519</Def>
            </StyledLink>
        </Item>
    </Root>
);

Contact.propTypes = {};

Contact.defaultProps = {};

export default Contact;
