import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const Root = styled.h3`
    color: ${(props) => `var(--clr-${props.color || 'theme-2'})`};
    font-family: var(--ff-title);
    font-size: var(--fs-lrg);
    line-height: var(--lh-title);
    margin-bottom: 3rem;
    padding-bottom: 2rem;
    position: relative;
    text-align: ${(props) => (props.isCentered ? 'center' : 'left')};
    text-indent: -0.08em;

    &::after {
        background: url('/images/dotline.svg') no-repeat 0 0;
        background-size: contain;
        bottom: 0;
        content: '';
        height: 0.3rem;
        left: ${(props) => (props.isCentered ? 'calc(50% - 2.6rem)' : 0)};
        position: absolute;
        width: 5.3rem;
    }

    @media (min-width: 500px) {
        font-size: var(--fs-xxlrg);
        margin-bottom: 4rem;

        &::after {
            height: 0.6rem;
            left: ${(props) => (props.isCentered ? 'calc(50% - 5.3rem)' : 0)};
            width: 10.6rem;
        }
    }
`;

const ContentTitle = ({ children, color, isCentered }) => (
    <Root color={color} isCentered={isCentered}>
        {children}
    </Root>
);

ContentTitle.propTypes = {};

ContentTitle.defaultProps = {};

export default ContentTitle;
