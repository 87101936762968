import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import Container from 'app/components/Container';
import Logo from 'app/components/Logo';
import PrimaryNavigation from 'app/components/PrimaryNavigation';

const Root = styled.header`
    color: var(--clr-white);
    left: 0;
    padding-top: 1rem;
    position: absolute;
    top: 0;
    width: 100%;

    @media (min-width: 500px) {
        padding-top: 2rem;
    }
`;

const Inner = styled.div`
    align-items: center;
    display: flex;
    justify-content: space-between;
`;

const Banner = () => (
    <Root>
        <Container>
            <Inner>
                <Logo />
                <PrimaryNavigation />
            </Inner>
        </Container>
    </Root>
);

Banner.propTypes = {};

Banner.defaultProps = {};

export default Banner;
